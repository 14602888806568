let vconsole: any

export async function initVConsole() {
  if (vconsole) return
  const { default: VConsole } = await import('@daangn/vconsole')
  vconsole = new VConsole()
}

export async function destroyVConsole() {
  await vconsole?.destroy()
}
