import { flow, map, partition, flatten, curry } from 'lodash/fp'

export const mapIf = curry(
  <T, R>(
    predicate: (item: T) => boolean,
    trueTransform: (item: T) => R,
    falseTransform: (item: T) => R,
    list: T[]
  ): R[] =>
    flow(
      partition(predicate),
      ([trueItems, falseItems]) => [map(trueTransform)(trueItems), map(falseTransform)(falseItems)],
      flatten
    )(list)
)
