import pRetry from 'p-retry'
import pTimeout from 'p-timeout'
import { commitMutation, Environment, fetchQuery } from 'relay-runtime'
import { from, ReplaySubject } from 'rxjs'
import UrlPattern from 'url-pattern'

import { JobPostPageQuery } from '@src/__generated__/JobPostPageQuery.graphql'
import {
  JobsInitialDataFetcherInitExperimentMutation,
  JobsInitialDataFetcherInitExperimentMutation$data,
} from '@src/__generated__/JobsInitialDataFetcherInitExperimentMutation.graphql'
import { JobsInitialDataFetcherQuery } from '@src/__generated__/JobsInitialDataFetcherQuery.graphql'
import { MainPageContentsQuery } from '@src/__generated__/MainPageContentsQuery.graphql'
import { MainPageFeedQuery } from '@src/__generated__/MainPageFeedQuery.graphql'
import { MainPageTopQuery } from '@src/__generated__/MainPageTopQuery.graphql'
import storage from '@src/api/storage'
import {
  JobsInitialDataFetcherInitExperimentMutationNode,
  JobsInitialDataFetcherInitExperimentMutationVariables,
  JobsInitialDataFetcherQueryNode,
} from '@src/components/JobsInitialDataFetcher.graphql'
import { JobPostPageQueryNode } from '@src/components/pageJobPost/JobPostPage.query'
import {
  MainPageContentsQueryNode,
  MainPageContentsQueryVariables,
} from '@src/components/pageMain/MainPageContents.query'
import {
  MainPageFeedQueryNode,
  MainPageFeedQueryPathToFilterState,
  MainPageFeedQueryVariables,
} from '@src/components/pageMain/MainPageFeed.query'
import { MainPageTopQueryNode } from '@src/components/pageMain/MainPageTop.query'
import { AUTH_TOKEN, IS_DEV, USER_AGENT } from '@src/config'
import { OPEN_INVITE_SHARE } from '@src/constants/action'
import { URL_SEGMENT_VALUE_CHARSET } from '@src/constants/url'
import { karrotBridgeV2 } from '@src/sdks/karrotBridge'
import { captureException } from '@src/sdks/sentry'
import { appInfoStorage } from '@src/utils/appInfo'
import { initExperimentSegments } from '@src/utils/experiment'

const BRIDGE_TIMEOUT = 200
export const experimentSyncSubject = new ReplaySubject<JobsInitialDataFetcherInitExperimentMutation$data>(1)

export async function preload({
  path,
  search,
  relayEnvironment,
}: {
  path: string
  search: string
  relayEnvironment: Environment
}) {
  const matchPath =
    (pattern: string) =>
    (path: string): null | { [key: string]: string } => {
      const p = new UrlPattern(pattern, { segmentValueCharset: URL_SEGMENT_VALUE_CHARSET })
      return p.match(path)
    }
  const initializeAppInfoSetting = async () => {
    const karrotBridgeInfo = await pRetry(
      async () => {
        if (IS_DEV) {
          return {
            // jobs-webview/src/hooks/useEnhancedInfo.ts
            app: {
              userAgent: USER_AGENT,
            },
            region: {
              id: 392,
              name1: '서울특별시',
              name2: '강남구',
              name3: '역삼1동',
            },
            user: {
              authToken: AUTH_TOKEN,
            },
          }
        }

        const r = await pTimeout(
          Promise.all([
            karrotBridgeV2.getAppInfo({}),
            karrotBridgeV2.getUserInfo({}),
            karrotBridgeV2.getRegionInfo({}),
          ]),
          {
            milliseconds: BRIDGE_TIMEOUT,
          }
        )

        const [
          {
            info: { app },
          },
          {
            info: { user },
          },
          {
            info: { region },
          },
        ] = r

        if (!app?.userAgent || !user?.authToken || !region?.id) {
          console.error(r)
          throw new Error('Invalid karrotBridge Info')
        }

        return {
          app,
          user,
          region,
        }
      },
      { retries: 5 }
    )

    appInfoStorage.setAppInfo({ userAgent: karrotBridgeInfo.app.userAgent })
    storage.setUserAgent(karrotBridgeInfo.app.userAgent)
    storage.setToken(karrotBridgeInfo.user.authToken)
    storage.setReferrerInfo({
      referrer: new URL(search, 'file://').searchParams.get('referrer') ?? undefined,
    })

    return karrotBridgeInfo
  }

  const karrotBridgeInfo = await initializeAppInfoSetting()
  const regionId = karrotBridgeInfo.region.id

  fetchQuery<JobsInitialDataFetcherQuery>(relayEnvironment, JobsInitialDataFetcherQueryNode, {
    eventGroupsWhere: {
      region: regionId,
      regionRange: 'RANGE_3',
    },
    regionExist: true,
  })
    .toPromise()
    .catch((err) => {
      console.error(err)
      captureException(err)
    })

  const [enableSegments, deletedSegments] = initExperimentSegments({ region: karrotBridgeInfo.region })
  from<Promise<JobsInitialDataFetcherInitExperimentMutation$data>>(
    new Promise((resolve, reject) => {
      commitMutation<JobsInitialDataFetcherInitExperimentMutation>(relayEnvironment, {
        mutation: JobsInitialDataFetcherInitExperimentMutationNode,
        variables: JobsInitialDataFetcherInitExperimentMutationVariables({
          enableSegments,
          deletedSegments,
        }),
        onCompleted: (res) => {
          resolve(res)
        },
        onError: (err) => {
          reject(err)
        },
      })
    })
  ).subscribe(experimentSyncSubject)

  const mainPageMatch = matchPath('/(main)(/)')(path)

  if (mainPageMatch) {
    const actions = new URL(search, 'file://').searchParams.get('actions')?.split(',')

    const r1 = fetchQuery<MainPageTopQuery>(relayEnvironment, MainPageTopQueryNode, {
      isInviteEvent: !!actions?.includes(OPEN_INVITE_SHARE),
    })
    const r2 = fetchQuery<MainPageContentsQuery>(
      relayEnvironment,
      MainPageContentsQueryNode,
      MainPageContentsQueryVariables({
        regionId,
      })
    )
    const r3 = fetchQuery<MainPageFeedQuery>(
      relayEnvironment,
      MainPageFeedQueryNode,
      MainPageFeedQueryVariables({
        regionId,
        filterState: MainPageFeedQueryPathToFilterState(path),
        bannersWhere: {
          region: regionId,
        },
      })
    )

    Promise.all([r1.toPromise(), r2.toPromise(), r3.toPromise()]).catch((err) => {
      console.error(err)
      captureException(err)
    })

    return
  }

  const jobPostPageMatch = matchPath('/job-post/:jobPostId(/)')(path)
  const jobPostId = jobPostPageMatch?.jobPostId
  if (jobPostId) {
    const r1 = fetchQuery<JobPostPageQuery>(relayEnvironment, JobPostPageQueryNode, {
      id: jobPostPageMatch.jobPostId,
      regionId,
    })

    r1.toPromise()
      .then((res) => {
        res?.jobPost?.images?.forEach((image) => {
          const img = new Image()
          img.src = image?.url ?? ''
        })
      })
      .catch((err) => {
        console.error(err)
        captureException(err)
      })

    return
  }
}
