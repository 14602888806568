import * as Sentry from '@sentry/browser'
import { ScopeContext } from '@sentry/types'

import storage from '@src/api/storage'
import { IS_NATIVE, APP_ENV, SENTRY_APP_VERSION, SENTRY_DSN } from '@src/config'

export const configSentry = (options: { userId?: string; nativeVersion?: string }) => {
  const scope = Sentry.getCurrentScope()
  scope.setUser({ ...scope.getUser(), id: options?.userId })
  scope.setTag('native_version', options?.nativeVersion)
}

export const captureException: typeof Sentry.captureException = (error, captureContext) => {
  const exception =
    error instanceof Error ? error : error && (error.error || error.message || error.originalError || error)

  return Sentry.captureException(exception, captureContext)
}

export const captureMessage = (message: string, options?: Partial<ScopeContext>) => {
  Sentry.captureMessage(message, {
    ...options,
    level: options?.level ?? 'warning',
  })
}

export const initSentry = () => {
  const ERROR_10_PERCENT_SAMPLING_MESSAGES = [
    'advertisement impression error',
    'advertisement click error',
    'advertisement hide error',
    'discovery visited error',
    'map load error',
  ]
  const ERROR_1_PERCENT_SAMPLING_MESSAGES = ['java object', 'java bridge']

  const checkSampling = (message: string) => {
    const random = Math.random()

    return (
      (ERROR_10_PERCENT_SAMPLING_MESSAGES.some((msg) => message.toLowerCase().includes(msg)) && random > 0.1) ||
      (ERROR_1_PERCENT_SAMPLING_MESSAGES.some((msg) => message.toLowerCase().includes(msg)) && random > 0.01)
    )
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_APP_VERSION,
    normalizeDepth: 10,
    environment: APP_ENV,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Loading chunk',
      'Aborted in beforeRetry() callback',
      "Redirected to 'Not Found' page",
      // Native Bridge 에서 발생하는 에러들
      't.messageHandler is not a function',
      'Java exception was raised during method invocation',
    ],
    denyUrls: [],
    beforeSend(event, hint) {
      if (!IS_NATIVE) return null

      const hintMessage = (hint.originalException as any)?.message
      const message = event.message || hintMessage

      if (message && checkSampling(message)) {
        return null
      }

      const { referrer, referrer_context } = storage.getReferrerInfo()

      event.extra = {
        ...event.extra,
        eventMessage: event.message,
        hintMessage,
      }

      event.tags = {
        ...event.tags,
        referrer,
        referrer_context,
      }

      return event
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const target = hint?.event?.target as HTMLElement
        if (target?.textContent) {
          breadcrumb.message = `click on ${target.tagName.toLocaleLowerCase()}[label="${target.textContent}"]`
          breadcrumb.level = 'info'
        }
        return breadcrumb
      }

      if (breadcrumb.category === 'ui.input') {
        const target = hint?.event?.target as HTMLInputElement
        if (target?.value) {
          breadcrumb.message = `input on ${target.tagName.toLocaleLowerCase()}[label="${target.name}"] = ${
            target.value
          }`
          breadcrumb.level = 'info'
        }
        return breadcrumb
      }

      return breadcrumb
    },
  })
}

initSentry()
