// action (querystring을 통한 Action trigger)

export const SHOW_REVIEWS_RECEIVED_AT_JOB_POST = 'SHOW_REVIEWS_RECEIVED'
export const SHOW_EXPERIENCE_AUTO_ADDED_AT_RESUME_MANAGE = 'SHOW_EXPERIENCE_AUTO_ADDED'
export const SHOW_AUTHOR_SECTION = 'SHOW_AUTHOR_SECTION'
export const SHOW_REVIEW_TO_WRITE = 'SHOW_REVIEW_TO_WRITE'

export const RESET_FILTERS = 'RESET_FILTERS'

// 인기알바 보여주기
export const SHOW_POPULAR_AUTHOR_REVIEWS = 'show_popular_author_reviews'
// 공유하기 이벤트 - 공유하기 띄움
export const OPEN_INVITE_SHARE = 'open_invite_share'
// 공유하기 이벤트 - 초대코드 등록
export const OPEN_INVITED_SUCCESS = 'open_invited_success'
// 공유하기 이벤트 - 초대코드 등록 실패
export const OPEN_INVITED_FAILURE = 'open_invited_failure'
// 공유하기 이벤트 - 알림 설정
export const SET_JOB_POST_FILTER_SUBSCRIPTION = 'set_job_post_filter_subscription'

export const SHOW_ADVERTISER_ADVERTISEMENT = 'show_advertiser_advertisement'

// legacy
// export const OPEN_ALARM_MENU = 'open_alarm_menu'
